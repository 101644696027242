import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Box, Flex } from "reflexbox"
import Layout from "../components/common/Layout"

import { Heading, Text, Ul } from "~/components/common/Typography"

import { Statistics, StatisticsRow } from "~/components/page/Statistics"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"

import { getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 0, mySubpage }) => {
  const data = useStaticQuery(graphql`
      query {
          intro: file(name: { eq: "intro" }) {
              childImageSharp {
                  fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_Video1_16x9_CTM_3bit" }
          ) {
              name
              publicURL
          }
          icon1: file(name: { eq: "ADIDAS-A1-kd" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          icon2: file(name: { eq: "ADIDAS-A2-kd" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          icon3: file(name: { eq: "ADIDAS-A3-TB" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          icon4: file(name: { eq: "ADIDAS-A4-TB" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          icon5: file(name: { eq: "1.1B_ADIDAS_TV" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          icon6: file(name: { eq: "1.1C_ADIDAS_TV" }) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { intro, video, icon1, icon2, icon3, icon4, icon5, icon6 } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `/`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingSmall={
          <span>
            Girls drop out of sports at twice the rate of boys by age 14.
            <Source>1</Source>
          </span>
        }
        headingLarge="Coaches have the power to change that."
        videoSrc={video.publicURL}
        videoLabel={video.name}
        headingLargeProps={{
          fontSize: ["24px", "32px", "54px"],
          lineHeight: ["34px", "37px", "67px"],
        }}
      />

      <Infobox>
        <Text fontFamily={"AdineuePROBoldWeb"} color={"black"} uppercase>
          If you are here, it’s likely you will be coaching girls.
        </Text>
        <Text fontFamily={"AdineuePROBoldWeb"} color={"black"} uppercase>
          adidas and Up2Us sports have created this curriculum to help you
          overcome stereotypes and knock down barriers that stand in the way of
          girls’ participation in sports. While strides have been made in the
          past few decades, the road is long and we have a ways to go. Girls
          have 1.3 million fewer opportunities to play high school sports than
          boys, and by age 14, they drop out of sports at twice the rate of
          boys.<Source>2</Source>
        </Text>
        <Text fontFamily={"AdineuePROBoldWeb"} color={"black"} uppercase>
          You as a coach can help change these statistics. You can be the
          greatest influence in the lives of your female athletes. This
          curriculum will provide you with the tools to keep your athletes
          playing, to inspire them on and off the field, and to empower them to
          overturn stereotypes and charge through life with the skills,
          confidence, and experience to be successful.
        </Text>
      </Infobox>

      <Statistics
        headerText="Why we need to keep girls in sports:"
        headerProps={{ mt: 6, background: "yellow" }}
      >
        <StatisticsRow
          headingText={
            <span>
              When they’re shooting layups, blocking goals, hitting home runs,
              and clearing hurdles, girls learn to appreciate the power of their
              own bodies.<Source>3</Source>
            </span>
          }
          image={icon1}
        ></StatisticsRow>
        <StatisticsRow
          reverse
          headingText={
            <span>
              Active, athletic girls have been found to have better self-esteem,
              more confidence, higher achievement in test scores, less
              depression, improved mental health, more academic success, and
              greater lifetime earning potential, according to the President's
              Council on Physical Fitness and Sports report.<Source>4</Source>
            </span>
          }
          image={icon2}
        ></StatisticsRow>
        <StatisticsRow
          headingText={
            <span>
              Studies about the “confidence gap” between men and women show that
              “girls who play team sports are more likely to graduate from
              college” and succeed in their careers.<Source>5</Source>
            </span>
          }
          image={icon3}
        ></StatisticsRow>
        <StatisticsRow
          reverse
          headingText={
            <span>
              On average, when girls play sports they score higher on
              achievement tests, graduate high school at a greater rate, and go
              on to have a higher earning potential than girls who don’t.
              <Source>6</Source>
            </span>
          }
          image={icon4}
        ></StatisticsRow>
        <StatisticsRow
          headingText={
            <span>
              Girls who play sports are 14% more likely to believe they are
              smart enough for their dream career.<Source>7</Source>
            </span>
          }
          image={icon5}
        ></StatisticsRow>
        <StatisticsRow
          mb={5}
          reverse
          headingText={
            <span>
              About 94% of women in executive positions have some background in
              sports.<Source>8</Source>
            </span>
          }
          image={icon6}
        ></StatisticsRow>
      </Statistics>

      <InfoboxDetailed
        headerText={"Why girls drop out of sports"}
        contentMaxWidth={["100%", "tablet", "mediumContent"]}
        pt={0}
      >
        <Text
          mb={0}
          textAlign="center"
          fontFamily={"PermanentMarkerRegular"}
          fontSize={["20px", "20px", "24px"]}
          lineHeight={["30px", "30px", "36px"]}
        >
          “As far as I'm concerned, our definition of feminine has been terribly
          distorted. We need to expand it in a healthy way to include the
          qualities of strong, competent, independent, competitive, and
          aggressive.”
        </Text>
        <Text
          uppercase
          textAlign={"center"}
          fontFamily={"AdineuePROBoldWeb"}
          mx={"auto"}
          mb={0}
          mt={3}
        >
          — Alan S. Goldberg, sports performance consultant<Source>9</Source>
        </Text>
        <Box>
          <Text>
            The barriers that stop girls from participating in sports range from
            physical to emotional to cultural. These six barriers are especially
            detrimental:
          </Text>
          <Ul
            uppercase
            textAlign={"left"}
            lineHeight={1.5}
            width={"90%"}
            mx={"auto"}
            fontFamily={"AdineuePROBoldWeb"}
          >
            <li>A lack of education and awareness of the benefits of sport</li>
            <li>Not enough coaches, mentors, and role models</li>
            <li>Limited access and opportunity to play</li>
            <li>Issues in safe transportation</li>
            <li>A lack of spaces and facilities</li>
            <li>Problematic stereotypes</li>
          </Ul>
          <Text>
            Adults encourage boys more often than girls to be independent and
            competitive in sports. Because of this, more girls lack the
            confidence to play sports, particularly as teens, and think of
            themselves as less athletic than they are. It therefore falls on you
            and other adults to dispel this fallacy, and show girls that they
            belong in sports just as much as boys.<Source>10</Source>
          </Text>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        pt={0}
        headerText={"Why you matter as a coach"}
        contentMaxWidth={["100%", "tablet", "mediumContent"]}
      >
        <Text textAlign="center">
          "One barrier that has been recently cited that prevents more women
          from progressing in sport, is the inability of coaches to understand
          how to engage their female athletes."<Source>11</Source>
        </Text>
        <Text textAlign="center">
          As cited in the report Why Children/Youth Drop Out of Sports, “Some
          coaches have poor communication skills, autocratic or authoritarian
          styles of interacting with young athletes, and are generally unable to
          relate to young athletes, which may lead athletes to choose to abandon
          participation.”<Source>12</Source>
        </Text>
        <Text textAlign="center">
          You as a coach can promote and maintain girls’ participation in sports
          by helping them understand their experience better and avoid or
          overcome obstacles that arise.
        </Text>
        <Text textAlign="center">
          Your voice is crucial in dispelling the myths about what girls’ bodies
          are capable of. Let your athletes know that girls can be fit, agile,
          powerful, muscular, and athletic.<Source>13</Source>
        </Text>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"TRAINING OBJECTIVES:"}
        headerBackground={"transparent"}
        contentMaxWidth={["100%", "tablet", "desktop"]}
        bg={"seafoam"}
      >
        <Box
          maxWidth="desktop"
          mx="auto"
          bg="white"
          mt="6"
          px={["8px", "24px", "40px"]}
          pt={[4, 5]}
          pb={[5, 6]}
          opacity="0.8"
        >
          <Flex flexDirection={["column", "row"]} width="100%" pt="5">
            <Box
              width={["100%", 1 / 2, 7 / 12]}
              pr={[0, 5]}
              textAlign={"left"}
              fontSize={["14px", "14px", "20px"]}
              lineHeight={["21px", "21px", "36px"]}
            >
              <Text mt={0}>
                The following lessons are designed to guide you in creating a
                team culture that encourages young athletes to fully
                participate, learn, and thrive in sports so that they can reach
                their highest potential both on and off the field.
                <br/>
                <br/> After completing this training, you as a coach will be
                able to:
              </Text>
              <Ul>
                <li>
                  Understand why relationships are important and how to
                  cultivate productive relationships between coaches and
                  athletes, as well as among team members.
                </li>
                <li>
                  Foster a positive and supportive team culture that encourages
                  participation and growth.
                </li>
                <li>
                  Recognize different stressors for athletes and have the
                  knowledge framework to respond in a personal and empathetic
                  way.
                </li>
                <li>
                  Master tactics for cultivating sports and life skills for
                  young athletes that can help them excel on the field and
                  beyond.
                </li>
                <li>
                  Understand how girls who are athletic in their youth grow up
                  to be healthy women.
                </li>
              </Ul>
            </Box>
            <Box width={["100%", 1 / 2, 5 / 12]} px={["19px", "0px"]}>
              <Img
                fluid={{
                  ...intro.childImageSharp.fluid,
                  aspectRatio: 1008 / 1360,
                }}
                alt="Woman basketball players"
                loading={"eager"}
              />
            </Box>
          </Flex>
          <Heading
            fontSize={["24px", "24px", "32px"]}
            lineHeight={["32px", "32px", "48px"]}
            my="6"
            textAlign="center"
            display="block"
          >
            The lessons learned in sports often have their greatest impact off
            the playing field. This curriculum will show you how.
          </Heading>
        </Box>
      </InfoboxDetailed>
      <Copyright bg={"seafoam"}/>
    </Layout>
  )
}

export default withProgress(Page, 0)
